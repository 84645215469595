import { useLessonContext } from "@/contexts/lesson-context";
import React, { useEffect } from "react";
import "./styles.scss";
import { MdModeEditOutline } from "react-icons/md";
import { currencyFormat, formatDate } from "@/utils/function";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { PaymentOptions } from "@/pages/add-money/Payment-options";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useAuth } from "@/contexts/auth-context";
import { fetchProfile } from "@/redux/slice/profile/profile";

export const Summary = () => {
  const { values } = useLessonContext();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { token } = useAuth();

  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.profileSlice);

  useEffect(() => {
    dispatch(fetchProfile({ token: token! }));
  }, []);

  return (
    <div>
      <div className="summary-container">
        <div className="summary-container-header">
          <p className="summary-title">{values.item.name}</p>
          <p className="summary-desc">{values.item.description}</p>
        </div>

        <div className="image-name-container">
          <img alt="img" className="dpUrl" src={values.teacher.dpUrl} />
          <p className="summary-name">
            {`${values.teacher.firstName} ${values.teacher.lastName}`}
          </p>
        </div>

        <div className="lesson-time-container">
          <p className="lesson-time-text">Lesson time</p>
          <MdModeEditOutline
            onClick={() => navigate(-2)}
            color="#169fed"
            size={24}
          />
        </div>

        <div className="summary-date-container">
          {values.timeSlots.map((item) => {
            return (
              <div>
                <span className="summary-date">
                  {formatDate(new Date(item.date))}
                  <span className="summary-time">{item.time}</span>
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="summary-fee-container">
        <div className="subtotal-tax-container">
          <p className="summary-subtotal">Subtotal</p>
          <p className="summary-subtotal-amount">
            {currencyFormat(values.amount)}
          </p>
        </div>
        <div className="subtotal-tax-container">
          <p className="summary-subtotal">+6% tax</p>
          <p className="summary-subtotal-amount">
            {currencyFormat(values.tax)}
          </p>
        </div>

        <button className="summary-btn" onClick={() => setIsOpen(true)}>
          Checkout
        </button>
      </div>
      <PaymentOptions
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        balance={data?.balance!}
      />
    </div>
  );
};
