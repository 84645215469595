import { Activity } from "@/types/activity";

import "./styles.scss";

interface ActivityListCardProp {
  activity: Activity;
  onClick: () => void;
}

export default function ActivityListCard({
  activity,
  onClick,
}: ActivityListCardProp) {
  return (
    <header className="activity-list-card" onClick={onClick}>
      <img
        className="activity-list-card-image-icon"
        src="/list-card-icon.png"
      />
      <h3 className="activity-list-card-header">{activity.title}</h3>
      <p className="activity-list-card-text">
        {0}/{activity.activities.length} lessons
      </p>
    </header>
  );
}
