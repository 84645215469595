import React from "react";
import "./styles.scss";

interface LessonCardProps {
  avatarUrl: string;
  rating: number;
  date: string;
  firstName: string;
  lastName: string;
  remark:string
}

const LessonCard: React.FC<LessonCardProps> = ({
  avatarUrl,
  rating,
  date,
  firstName,
  lastName,
  remark
}) => {
  return (
    <div className="lesson-card">
      <img src={avatarUrl} alt="User Avatar" className="lesson-card__avatar" />
      <div
        className="lesson-card__content"
      >
        <div className="lesson-card__header">
          {firstName} {lastName}
        </div>
        <div className="lesson-completed">rating:{rating}/5</div>
        <div className="lesson-body">{remark}</div>
        <div className="lesson-card__footer">{date}</div>
      </div>
    </div>
  );
};

export default LessonCard;
