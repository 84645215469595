import {
  ILessonsResultResponse,
  IReview,
  IStatsResultResponse,
  TeacherData,
} from "@/types/teacher";
import { GET } from "../api-request";
import { useQuery } from "@tanstack/react-query";

export const getTeachers = async () => {
  const token = localStorage.getItem("pluri_token");
  const { response } = await GET(`/teachers`, token ?? "");
  return new Promise<TeacherData[]>((resolve) => resolve(response.data.result));
};

export const useGetTeacher = ({ id }: { id: string }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["teacher", id],
    queryFn: async () => {
      const token = localStorage.getItem("pluri_token");
      const { response } = await GET(`/teachers/${id}`, token ?? "");
      return response.data.result;
    },
    enabled: !!id,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: Infinity,
  });

  return {
    teacher: data,
    isLoading,
    error,
  };
};

export const useGetTeacherReview = ({ id }: { id: string }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["reviews", id],
    queryFn: async (): Promise<{
      reviews: IReview[];
      averageRating: number;
      totalReview: number;
    }> => {
      const token = localStorage.getItem("pluri_token");
      const { response } = await GET(`/teachers/reviews/${id}`, token ?? "");
      return response.data.result;
    },
    enabled: !!id,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    reviews: data,
    isLoading,
    error,
  };
};

export const useGetTeacherLessons = ({ id }: { id: string }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["lessons", id],
    queryFn: async (): Promise<ILessonsResultResponse> => {
      const token = localStorage.getItem("pluri_token");
      const { response } = await GET(`/teachers/${id}/lessons`, token ?? "");
      return response.data;
    },
    enabled: !!id,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
  return {
    lessons: data,
    isLoading,
    error,
  };
};

export const useGetTeacherStats = ({ id }: { id: string }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["stat", id],
    queryFn: async (): Promise<IStatsResultResponse> => {
      const token = localStorage.getItem("pluri_token");
      const { response } = await GET(`/teachers/stats/${id}`, token ?? "");
      return response.data;
    },
    enabled: !!id,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
  return {
    stats: data?.result,
    statLoading: isLoading,
    statError: error,
  };
};
