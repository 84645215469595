import { Button, Icon } from "@/components";
import "./styles.scss";
import { ProfileCard } from "./ProfileCard";
import { useEffect, useMemo, useRef, useState } from "react";
import EditListType from "./EditListType";
import { IProfile } from "@/types/profile";
import { interestList, languageList } from "@/mock/profile";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { fetchProfile, updateUserProfile } from "@/redux/slice/profile/profile";
import { useAuth } from "@/contexts/auth-context";
import { camelToSnakeCase, debounce } from "@/utils/function";
import SelectFromList from "./SelectFromList";
import { fetchCountryAndStates } from "@/redux/slice/profile/countryAndStates";
import DateOfBirthPicker from "./DOBPicker";
import { formatStringtoDate } from "@/utils/function";

export function EditProfile({
  open,
  onClose,
  profile,
  setUpdating,
}: {
  profile: IProfile;
  open: boolean;
  onClose: () => void;
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [tempProfile, setTempProfile] = useState<IProfile>({ ...profile });
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { countries } = useAppSelector((state) => state.countrySlice);
  console.warn(profile);
  const countryList = useMemo(() => {
    return countries.map((country) => ({
      id: country.iso2,
      value: country.name,
    }));
  }, [countries]);
  const statesList = useMemo(() => {
    const country = countries?.find(
      (country) => country.iso2 === tempProfile?.countryOfResidence
    );
    return country?.states.map((state) => ({
      id: state.state_code,
      value: state.name,
    }));
  }, [tempProfile?.countryOfResidence]);

  useEffect(() => setTempProfile(profile), [profile]);
  useEffect(() => {
    dispatch(fetchCountryAndStates());
  }, []);

  const debouncedDispatch = useRef(
    debounce((name: keyof IProfile, value: string | Array<string>) => {
      dispatch(
        updateUserProfile({ token: token!, profileData: { [name]: value } })
      ).then(() => {
        dispatch(fetchProfile({ token: token! })).then(() => {
          setUpdating(false);
        });
      });
    }, 700)
  ).current;

  const handleProfileEdit = (
    value: string | Array<string>,
    name: keyof IProfile
  ) => {
    setTempProfile((p) => ({ ...p, [name]: value }));
  };

  const handleBlur = (name: keyof IProfile) => {
    const toBeConverted = ["firstName", "lastName", "dateOfBirth"];
    let snakeCaseName = name;
    if (toBeConverted.includes(snakeCaseName)) {
      snakeCaseName = camelToSnakeCase(name.toString()) as keyof IProfile;
    }
    debouncedDispatch(snakeCaseName, tempProfile[name] as string);
  };

  const [activeEdit, setActiveEdit] = useState<string>();
  const removeLanguage = (language: { value: string }) => {
    const newList = tempProfile.languagesSpoke.filter(
      (item) => item !== language.value
    );
    setTempProfile((data) => ({ ...data, languagesSpoke: newList }));
    debouncedDispatch("languagesSpoke" as keyof IProfile, newList);
  };

  const removeInterests = (interest: { value: string }) => {
    const newList = tempProfile.Interests.filter(
      (item) => item !== interest.value
    );
    setTempProfile((data) => ({ ...data, interests: newList }));
    debouncedDispatch("interests" as keyof IProfile, newList);
  };

  const genderList = [
    {
      id: "Male",
      value: "Male",
    },
    {
      id: "Female",
      value: "Female",
    },
  ];

  const setLanguageActive = () => setActiveEdit("languages");
  const setInterestsActive = () => setActiveEdit("interests");
  const setCurrentCountryActive = () => setActiveEdit("countryOfResidence");
  const setCurrentCityActive = () => setActiveEdit("stateOfResidence");
  const setNationalityActive = () => setActiveEdit("nationality");
  const setGenderActive = () => setActiveEdit("gender");
  const setDOBActive = () => setActiveEdit("dateOfBirth");

  if (!open) return <></>;
  if (activeEdit === "languages")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>

        <EditListType
          title="Pick Languages"
          list={languageList}
          onChange={(languages) => {
            const languagesSpoke = languages.map((item) => item.value);
            setTempProfile((data) => ({
              ...data,
              languagesSpoke,
            }));
            //uncomment this if you want it to be edittable on backend
            // debouncedDispatch(
            //   "languagesSpoke" as keyof IProfile,
            //   languagesSpoke
            // );
          }}
          selected_items={(tempProfile?.languagesSpoke ?? []).map(
            (item, index) => ({ value: item, id: String(index) })
          )}
        />
      </div>
    );
  if (activeEdit === "interests")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <EditListType
          title="Pick Interests"
          list={interestList}
          onChange={(interestsList) => {
            const interests = interestsList.map((item) => item.value);
            setTempProfile((data) => ({
              ...data,
              interests,
            }));
            //uncomment this if you want it to be edittable on backend
            debouncedDispatch("interests" as keyof IProfile, interests);
          }}
          selected_items={(tempProfile?.Interests ?? []).map((item, index) => ({
            value: item,
            id: String(index),
          }))}
        />
      </div>
    );
  if (activeEdit === "dateOfBirth")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <DateOfBirthPicker
          onDateChange={(date) =>
            debouncedDispatch(
              "dateOfBirth" as keyof IProfile,
              date?.toDateString() as string
            )
          }
        />
      </div>
    );
  if (activeEdit === "gender")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <SelectFromList
          title="Gender"
          list={genderList}
          onChange={(gender) => {
            if (!gender) return;
            debouncedDispatch("gender" as keyof IProfile, gender?.id);
            setActiveEdit(undefined);
          }}
          selectedItem={
            genderList.find((item) => item.id === tempProfile.gender) ?? null
          }
        />
      </div>
    );
  if (activeEdit === "nationality")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <SelectFromList
          title="Currently Lives In"
          list={countryList}
          onChange={(country) => {
            if (!country) return;
            debouncedDispatch("countryOfOrigin" as keyof IProfile, country?.id);
            setActiveEdit(undefined);
          }}
          selectedItem={
            countryList.find(
              (item) => item.id === tempProfile.countryOfOrigin
            ) ?? null
          }
        />
      </div>
    );
  if (activeEdit === "countryOfResidence")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <SelectFromList
          title="Currently Lives In"
          list={countryList}
          onChange={(country) => {
            if (!country) return;
            debouncedDispatch(
              "countryOfResidence" as keyof IProfile,
              country?.id
            );
            setActiveEdit(undefined);
          }}
          selectedItem={
            countryList.find(
              (item) => item.id === tempProfile.countryOfResidence
            ) ?? null
          }
        />
      </div>
    );
  if (activeEdit === "stateOfResidence")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <SelectFromList
          title="State of Residence"
          list={statesList ?? []}
          onChange={(state) => {
            if (!state) return;
            debouncedDispatch("stateOfResidence" as keyof IProfile, state?.id);
            setActiveEdit(undefined);
          }}
          selectedItem={
            statesList?.find(
              (item) => item.id === tempProfile.stateOfResidence
            ) ?? null
          }
        />
      </div>
    );

  return (
    <div className="edit_profile--container">
      <Button variant="neutral" className="close-btn" onClick={onClose}>
        <Icon name="close" />
        <span>Close</span>
      </Button>

      <h1 className="heading">Edit Profile</h1>

      <ProfileCard
        title="First Name"
        value={`${tempProfile.firstName}`}
        type="text"
        isEditable
        onChange={(value) => handleProfileEdit(value, "firstName")}
        onBlur={() => handleBlur("firstName")}
      />
      <ProfileCard
        title="Last Name"
        value={`${tempProfile.lastName}`}
        type="text"
        isEditable
        onChange={(value) => handleProfileEdit(value, "lastName")}
        onBlur={() => handleBlur("lastName")}
      />
      <ProfileCard
        title="Date of Birth"
        value={
          tempProfile.dateOfBirth
            ? formatStringtoDate(tempProfile.dateOfBirth)
            : ""
        }
        type="selectOne"
        onClick={setDOBActive}
        isEditable
      />
      <ProfileCard
        title="Gender"
        value={tempProfile.gender || ""}
        type="selectOne"
        onClick={setGenderActive}
        isEditable
      />
      <ProfileCard
        title="Nationality"
        value={
          countries?.find((item) => item.iso2 === tempProfile?.countryOfOrigin)
            ?.name || ""
        }
        type="selectOne"
        onClick={setNationalityActive}
        isEditable
      />
      <ProfileCard
        title="Country of Residence"
        value={
          countries?.find(
            (item) => item.iso2 === tempProfile?.countryOfResidence
          )?.name || ""
        }
        type="selectOne"
        onClick={setCurrentCountryActive}
        isEditable
      />
      <ProfileCard
        title="State of Residence"
        value={
          statesList?.find((item) => item.id === tempProfile?.stateOfResidence)
            ?.value || ""
        }
        type="selectOne"
        onClick={setCurrentCityActive}
        isEditable
      />
      <ProfileCard
        title="Speaks"
        value={(tempProfile?.languagesSpoke ?? []).map((item) => ({
          value: item,
        }))}
        type="list"
        onRemoveItem={removeLanguage}
        onClick={setLanguageActive}
        isEditable
      />

      <ProfileCard
        title="Interest"
        value={(tempProfile?.Interests ?? []).map((item) => ({ value: item }))}
        type="list"
        onRemoveItem={removeInterests}
        onClick={setInterestsActive}
        isEditable
      />
    </div>
  );
}
