import { request } from "./request";

export const GET = async (endPoint: string, token: string) => {
  const response = await request.get(endPoint, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return { response };
};

export const POST = async (
  endPoint: string,
  body: any,
  token: string,
  ContentType?: "string"
) => {
  const response = await request.post(endPoint, body, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": ContentType ? ContentType : "application/json",
    },
  });

  return { response };
};

export const PUT = async (endPoint: string, body: any, token: string) => {
  const response = await request.put(endPoint, body, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  return { response };
};

export const PATCH = async (endPoint: string, body: any, token: string) => {
  const response = await request.patch(endPoint, body, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  return { response };
};

export const DELETE = async (endPoint: string, body: any, token: string) => {
  const response = await request.delete(
    endPoint,
    body
    //     {
    //     headers: {
    //       authorization: `Bearer ${token}`,
    //       "Content-Type": "application/json",
    //     },
    //   }
  );

  return { response };
};
