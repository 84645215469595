import "./styles.scss";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { FaTimes } from "react-icons/fa";

interface IChat {
  message: string;
  date: string;
  sender: string;
  avatar: string;
}

interface IChats {
  chats: IChat[];
}

const Chat = ({ chats }: IChats) => {
  return (
    <div className="chat-container">
      {/* Chat Header */}
      <div className="details-head">
        <h2 className="details-title">{"cheche sober"}</h2>
        <p className="details-close">
          <FaTimes className="font-bold" size={18} color="#FF5252" /> <span>Close</span>
        </p>
      </div>
      {/* Chat Body */}
      <div className="chat-body">
        {chats.map((chat, index) => (
          <div className="chat-item-container" key={index}>
            {index === 0 || chats[index - 1].date !== chat.date ? (
              <div className="chat-date">{chat.date}</div>
            ) : null}
            <div
              className={`chat-item ${
                chat.sender === "me" ? "sent" : "received"
              }`}
            >
              <div className="image-container">
                {/* <img
                  src={chat.avatar}
                  alt={chat.sender}
                  className="chat-avatar"
                /> */}
              </div>
              <div className="chat-content">
                <p className="chat-sender">
                  {chat.sender === "me" ? "You" : chat.sender}
                </p>
                <p className="chat-message">{chat.message}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Chat Footer */}
      <div className="chat-footer">
        <textarea
          cols={3}
          rows={2}
          className="chat-input"
          placeholder="Your Message"
        />
        <button className="chat-send">
          <PiPaperPlaneRightFill size={30} />
        </button>
      </div>
    </div>
  );
};

export default Chat;
