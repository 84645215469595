import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@/contexts/auth-context";
import { useAppDispatch } from "@/redux/hooks";
import { fetchLanguages } from "@/redux/slice/language/languages";
import { useLanguageContext } from "@/contexts/language-context";
import { Icon } from "@/components";
import "./header.scss";

export default function Header() {
  const { details, token } = useAuth();
  const dispatch = useAppDispatch();
  const { values, updateLanguage, setAvailableLanguages, resetLanguage } =
    useLanguageContext();

  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(
      fetchLanguages({
        token: token || "",
        updateLanguages: (languages) =>
          setAvailableLanguages(languages.map((lang) => lang.name)),
      })
    );
  }, [token, dispatch, setAvailableLanguages]);

  const filteredLanguages = values.availableLanguages.filter((language) =>
    language.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="header">
      <h1 className="greeting">
        {details ? `Hi, ${details.firstName} ${details.lastName}` : "Hi"}
      </h1>

      <div className="description">
        <Link to="/notification">
          <Icon name="message" />
        </Link>
        <div
          className="language-and-arrow"
          onClick={() => setShowModal((prev) => !prev)}
        >
          <div>{values.selectedLanguage || "Select"}</div>
          <Icon name="downArrow" />
        </div>
      </div>

      {showModal && (
        <div className="languageTopContainer">
          <button
            className="closeButton"
            onClick={() => {
              resetLanguage();
              setShowModal(false);
            }}
          >
            <Icon name="close" />
          </button>
          <input
            type="text"
            className="searchInput"
            placeholder="Search languages..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {filteredLanguages.length > 0 ? (
            filteredLanguages.map((language, index) => (
              <div
                key={index}
                className="langTextContain"
                onClick={() => {
                  updateLanguage(language);
                  setShowModal(false);
                }}
              >
                <p className="langText">{language}</p>
              </div>
            ))
          ) : (
            <p className="langText">No languages match your search.</p>
          )}
        </div>
      )}
    </div>
  );
}
