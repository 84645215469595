import { Button, Icon } from "@/components";
import "./styles.scss";

export default function ContactUs({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  if (!open) return <></>;
  return (
    <div className="contact_us--container">
      <Button variant="neutral" className="close-btn" onClick={onClose}>
        <Icon name="close" />
        <span>Close</span>
      </Button>

      <h1 className="heading">Contact Us</h1>
      <div className="btn-list">
        <a target="blank" className="social-link" href="tel:+2347077339344">
          <Button variant="neutral" className="btn-item">
            <div className="icon-container">
              <Icon name="phone" />
            </div>
            <div>
              <span className="title">Call us</span>
              <span className="sub-title">
                Speak to someone from our call center
              </span>
            </div>
          </Button>
        </a>
        <a
          target="blank"
          className="social-link"
          href={`mailto:pluritonguesllc@gmail.com`}
        >
          <Button variant="neutral" className="btn-item">
            <div className="icon-container">
              <Icon name="envelope" />
            </div>
            <div>
              <span className="title">Send an email</span>
              <span className="sub-title">
                Speak to someone from our call center
              </span>
            </div>
          </Button>
        </a>
        <a
          target="blank"
          className="social-link"
          href={`https://wa.me/2347077339344`}
        >
          <Button variant="neutral" className="btn-item">
            <div className="icon-container">
              <Icon name="whatsapp" />
            </div>
            <div>
              <span className="title">Chat with us</span>
              <span className="sub-title">
                Speak to someone from our call center
              </span>
            </div>
          </Button>
        </a>
      </div>
      <h1 className="heading">Reach us on social media</h1>
      <div className="btn-list">
        <a
          target="blank"
          className="social-link"
          href={`https://x.com/pluritongues`}
        >
          <Button variant="neutral" className="btn-item">
            <div className="icon-container">
              <Icon name="x_logo" />
            </div>
            <div>
              <span className="title">X</span>
            </div>
          </Button>
        </a>
        <a
          target="blank"
          className="social-link"
          href={`https://instagram.com/pluritongues`}
        >
          <Button variant="neutral" className="btn-item">
            <div className="icon-container">
              <Icon name="instagram" />
            </div>
            <div>
              <span className="title">Instagram</span>
            </div>
          </Button>
        </a>
        <a
          target="blank"
          className="social-link"
          href={`https://linkedin.com/company/pluritongues`}
        >
          <Button variant="neutral" className="btn-item">
            <div className="icon-container">
              <Icon name="linkedin" />
            </div>
            <div>
              <span className="title">LinkedIn</span>
            </div>
          </Button>
        </a>
        <a
          target="blank"
          className="social-link"
          href={`https://facebook.com/profile.php?id=61550359238161`}
        >
          <Button variant="neutral" className="btn-item">
            <div className="icon-container">
              <Icon name="facebook" />
            </div>
            <div>
              <span className="title">facebook</span>
            </div>
          </Button>
        </a>
      </div>
    </div>
  );
}
