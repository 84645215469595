import { Button, Icon, PasswordInput } from "@/components";
import "./styles.scss";
import { ChangeEvent, FormEvent, useState } from "react";
import { useResetPassword } from "@/services/profile/post";
import { useAuth } from "@/contexts/auth-context";

export default function PasswordSettings({ onClose }: { onClose: () => void }) {
  const {details,token} = useAuth()
  const { resetPassword, resetting } = useResetPassword(() => onClose());
  const [formData, setFormData] = useState<{
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const validate = () => {
    const errors: typeof formErrors = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    if (!formData.oldPassword.trim()) {
      errors.oldPassword = "Old password is required.";
    }

    if (!formData.newPassword.trim()) {
      errors.newPassword = "New password is required.";
    } else if (formData.newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters.";
    }

    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = "Please confirm your new password.";
    } else if (formData.newPassword !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    setFormErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
    setFormErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = details?.email ||"";

    if (validate()) {
      resetPassword({
        data: {
          email,
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword,
        },
        token: token ?? "",
      });
    }
  };

  return (
    <div className="password-settings">
      <Button variant="neutral" className="back-btn" onClick={onClose}>
        <Icon name="left_arrow" />
        <span>Back</span>
      </Button>

      <h1 className="heading">Password Settings</h1>

      <form className="inputs" onSubmit={handleSubmit}>
        <PasswordInput
          error={{ message: formErrors.oldPassword }}
          name="oldPassword"
          onChange={handleChange}
          placeholder="Enter your old password"
          label={"Old Password"}
        />
        <PasswordInput
          error={{ message: formErrors.newPassword }}
          name="newPassword"
          onChange={handleChange}
          placeholder="Enter your new password"
          label={"New Password"}
        />
        <PasswordInput
          error={{ message: formErrors.confirmPassword }}
          name="confirmPassword"
          onChange={handleChange}
          placeholder="Confirm your new password"
          label={"Confirm New Password"}
        />

        <div className="continue_btn--container">
          <Button
            isLoading={resetting}
            variant="neutral"
            disabled={Object.values(formErrors).some((error) => error)}
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
}
