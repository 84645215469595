import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

interface DateOfBirthPickerProps {
  onDateChange: (date: Date | null) => void; // Callback to handle selected date
}

const DateOfBirthPicker: React.FC<DateOfBirthPickerProps> = ({
  onDateChange,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onDateChange(date);
  };

  return (
    <div className="dob-picker">
      <label htmlFor="dob" className="dob-label">
        Date of Birth:
      </label>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        className="dob-input"
        placeholderText="Select your date of birth"
        dateFormat="MMMM d, yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        maxDate={new Date()}
        id="dob"
      />
    </div>
  );
};

export default DateOfBirthPicker;
