import React, { useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { checkBalance, currencyFormat } from "@/utils/function";

interface PaymentModalProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  balance: number;
}

export const PaymentModal: React.FC<PaymentModalProps> = ({
  setIsOpen,
  isOpen,
  balance,
}) => {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();

  const handleCloseModal = () => setIsOpen(false);

  const handleContinueToPay = () => {
    if (checkBalance(balance, parseInt(amount)).result) {
      const possibleAmountToAdd =
        checkBalance(balance, parseInt(amount)).possibleAmountToAdd ?? 0; // Fallback to 0

      toast.warning(
        `Your balance cannot exceed $500. Add ${currencyFormat(
          possibleAmountToAdd
        )} to reach $500.`
      );
    } else {
      toast.warning(`Proceeding with payment of $${amount}`, {
        richColors: true,
      });
      setIsOpen(false);
      navigate("./payment", {
        state: {
          amount,
        },
      });
    }
  };

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button onClick={handleCloseModal} className="modal-close">
              X
            </button>
            <h2>Enter Amount</h2>
            <input
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => {
                const replacedText = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^0(?!\.)/, "")
                  .replace(/(\.\d{2})\d+/, "$1")
                  .replace(/^([1-9]\d*(\.\d{0,2})?).*$/, "$1");

                setAmount(replacedText);
              }}
              className="modal-input"
            />
            <button onClick={handleContinueToPay} className="modal-button">
              Continue to Pay
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
