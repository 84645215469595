import React from "react";
import ReactCalendar, {
  type CalendarProps as BaseCalendarProps,
} from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Icon from "../Icon";
import "./styles.scss";
import { generateTimeSlotsForDates } from "@/utils/function";

interface CustomCalendarProps extends BaseCalendarProps {
  data?: { start: string; end: string }[];
  dateSelected: { date: string; time: string }[]; // Corrected type for dateSelected
  setDateSelected: (dates: { date: string; time: string }[]) => void; // / Accept an array of Date objects
  onDateClick: (type: string, value: Date | string) => void;
  picked: string | Date;
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const DetailCalendar = ({
  data = [],
  dateSelected,
  setDateSelected,
  onDateClick,
  value,
  onChange,
  picked,
  ...props
}: CustomCalendarProps) => {
  const PrevLabel = <Icon name="caret_left" />;
  const NextLabel = <Icon name="caret_right_bold" color="white" />;

  const formatShortWeekday = (locale: string | undefined, date: Date) =>
    ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()];

  const availableDates = new Set(
    data.flatMap(({ start, end }) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const date = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
      const endNormalizedDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      );

      const dates = [];
      while (date <= endNormalizedDate) {
        dates.push(date.toLocaleDateString());
        date.setDate(date.getDate() + 1);
      }

      return dates;
    })
  );

  const isDateAvailable = (date: Date): boolean => {
    return availableDates.has(date.toLocaleDateString());
  };

  const isSelectedDate = (
    date: string | Date,
    dateArray: { date: string; time: string }[]
  ): boolean => {
    return dateArray.some(
      (item) => new Date(item.date).getTime() === new Date(date).getTime()
    );
  };

  const handleChange = (
    value: Value,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (value instanceof Date) {
      onDateClick("day", value);
    } else if (Array.isArray(value) && value.length === 2) {
      const [start, end] = value;
      if (start instanceof Date && end instanceof Date) {
        console.log("Date range selected:", start, end);
      }
    } else if (value === null) {
      console.log("No date selected");
    } else if (typeof value === "string") {
      console.log("String value selected:", value);
    }
  };

  return (
    <>
      <ReactCalendar
        value={value}
        onChange={handleChange}
        prevLabel={PrevLabel}
        nextLabel={NextLabel}
        formatShortWeekday={formatShortWeekday}
        tileClassName={({ date, view }) => {
          if (view === "month") {
            return isSelectedDate(date, dateSelected)
              ? "tile-active"
              : isDateAvailable(date)
              ? "available-date"
              : "unavailable-date";
          }
          return "";
        }}
        {...props}
      />
      <div>
        <p className="availableText">Available Time</p>
        {data &&
          (picked ? (
            generateTimeSlotsForDates(data, picked).map((item) => {
              console.warn(item, picked);
              return (
                <div className="time-container" key={item.startTime}>
                  <p className="time-frame">
                    {item.startTime} - {item.endTime}
                  </p>
                </div>
              );
            })
          ) : generateTimeSlotsForDates(data, picked).length < 1 ? (
            <p className="no-dates">No available period</p>
          ) : (
            <p className="no-dates">
              Click on a day to see teacher available time
            </p>
          ))}
      </div>
    </>
  );
};
