import { Button, PasswordInput, TextInput } from "@/components";
import { Login, useAuth } from "@/contexts/auth-context";
import { ChangeEvent, FormEvent, useState } from "react";
import "./styles.scss";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

export default function SignInForm() {
  const [isLoading, setIsLoading] = useState(false);
  const { handleLogin } = useAuth();
  const [formData, setFormData] = useState<Login>({ email: "", password: "" });
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    handleLogin?.(formData)
      .then(() => {
        setFormData({ email: "", password: "" });
        toast.success("Sign in successful", { richColors: true });
        navigate("/activity");
      })
      .catch((error) =>
        toast.error(
          error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Couldn't sign you in"
        )
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <form className="signin_form" onSubmit={handleSubmit}>
      <TextInput
        label={"Email"}
        type="email"
        placeholder="email"
        name="email"
        className=""
        onChange={handleChange}
      />
      <PasswordInput
        label={"Password"}
        placeholder="password"
        name="password"
        onChange={handleChange}
      />
      <Button
        variant="primary"
        className="signin_btn"
        type="submit"
        isLoading={isLoading}
        disabled={isLoading}
      >
        Sign in
      </Button>
    </form>
  );
}
