import DashboardLayout from "@/layouts/dashboard-layout";
import "./styles.scss";
import { Avatar, Button, Icon } from "@/components";
import Wallet from "./components/Wallet";
import ProfileButton from "./components/ProfileButton";
import { EditProfile } from "./components/EditProfile";
import { useEffect, useState } from "react";
import ContactUs from "./components/ContactUs";
import Settings from "./components/Settings";
import {
  fetchProfile,
  uploadProfilePicture,
} from "@/redux/slice/profile/profile";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useAuth } from "@/contexts/auth-context";

export default function Profile() {
  const [updating, setUpdating] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const { token, handleLogout } = useAuth();
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((state) => state.profileSlice);

  useEffect(() => {
    dispatch(fetchProfile({ token: token! }));
  }, []);

  const handleOpenSettings = () => {
    setOpenSettings(true);
    setOpenEdit(false);
    setOpenContact(false);
  };

  const handleOpenContact = () => {
    setOpenSettings(false);
    setOpenEdit(false);
    setOpenContact(true);
  };

  const handleOpenEdit = () => {
    setOpenSettings(false);
    setOpenEdit(true);
    setOpenContact(false);
  };

  const handleUpload = (file: File) => {
    dispatch(uploadProfilePicture({ token: token!, file }));
  };

  return (
    <DashboardLayout>
      {loading && !updating ? (
        <div>Loading...</div>
      ) : (
        <div className="profile_container">
          <div className="left">
            <div className="main_profile">
              <Avatar src={data?.profilePictureUrl!} onUpload={handleUpload} />
              <h1>
                {data?.firstName} {data?.lastName}
              </h1>
              <Button
                variant="neutral"
                className="edit-profile-btn"
                onClick={handleOpenEdit}
              >
                <span>Edit Profile</span>
                <Icon name="caret_right" />
              </Button>
            </div>
            <Wallet balance={data?.balance!} />
            <ProfileButton
              icon="settings"
              title="Settings"
              onClick={handleOpenSettings}
            />
            <ProfileButton
              icon="contact"
              title="Contact Us"
              onClick={handleOpenContact}
            />
            <ProfileButton
              onClick={handleLogout}
              icon="logout"
              title="Log Out"
            />
          </div>
          <div
            className="right"
            data-show={String(openEdit || openSettings || openContact)}
          >
            <EditProfile
              profile={data!}
              open={openEdit}
              onClose={() => setOpenEdit(false)}
              setUpdating={setUpdating}
            />
            <ContactUs
              open={openContact}
              onClose={() => setOpenContact(false)}
            />
            <Settings
              profile={data!}
              open={openSettings}
              onClose={() => setOpenSettings(false)}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}
