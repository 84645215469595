import { NavLink } from "react-router-dom";
import "./interactive-lesson-card.scss";
import { Button } from "@/components";
import { useNavigate } from "react-router-dom";
import { Blogs } from "@/redux/slice/blog";
import { useState } from "react";

interface DashBoardTeacherCardProps {
  title: string;
  buttoText: string;
  onButtonClick?: () => void;
}

interface BlogCardProps {
  data: Blogs[] | null; // Adjust this type to match your data structure
}

export function InteractiveLessonCard() {
  const navigate = useNavigate();

  return (
    <article className="dashboard-interactive-card">
      <div className="item">A</div>
      <div className="item">
        <h3>Interactive Activities</h3>
        <p>Level A1 (Beginner)</p>
        <p>0/10 Lessons</p>
        <progress value="0" max="100"></progress>
      </div>

      <Button
        className="item"
        variant="secondary"
        onClick={() => {
          navigate("/activity");
        }}
      >
        Start
      </Button>
    </article>
  );
}

export function InteractiveLessonCardx() {
  return (
    <article className="grid-container">
      <div className="item">A</div>
      <div className="item">
        <h2>Interactive Activities</h2>
        <p>Level A1 (Beginner)</p>
        <p>0/10 Lessons</p>
      </div>
      <Button className="item" variant="secondary">
        Start
      </Button>
    </article>
  );
}

export function DashBoardTeacherCard({
  title,
  buttoText,
  onButtonClick,
}: DashBoardTeacherCardProps) {
  return (
    <article className="dashboard-card">
      {/* Note: Kind of prefer h3 for card heading since it might  be inside a section which is in a page */}
      <h3 className="card-title">{title}</h3>
      <button className="card-buttons" onClick={onButtonClick}>
        {buttoText}
      </button>
    </article>
  );
}

export function ScheduleCard() {
  return (
    <article className="schedule-card">
      <section>
        <h3 className="schedule-title">Schedules</h3>
        <p className="inline-text">Find your class & session details here.</p>
      </section>
      <aside>
        {/* src/pages/home/interactive-lesson-card/images/schedule.svg */}
        <img src="/schedule.svg" alt="Pluritongues Schedule" />
      </aside>
    </article>
  );
}

export const BlogCard: React.FC<BlogCardProps> = ({ data }) => {
  const [seeAll, setSeeAll] = useState(3);
  return (
    <article className="blog-card">
      <section>
        <h3 className="blog-title">From Our Blog</h3>
      </section>
      {!data || data?.length < 1 ? (
        <p>No blogs at the moment</p>
      ) : (
        data?.slice(0, seeAll).map((item) => {
          return (
            <aside className="blog-container">
              <div className="blog-image-container">
                <img
                  src={item.image_url}
                  alt="n "
                  className="blog-card-image"
                />
              </div>
              <div className="blog-text-date">
                <h3 className="blog-card-title">{item.title}</h3>
                <p className="blog-date">{item.date_published}</p>
              </div>
            </aside>
          );
        })
      )}
      
      {data && data?.length > 1 ? (
        <button
          onClick={() => setSeeAll(seeAll === 3 ? data.length + 1 : 3)}
          className="blog-see-more"
        >
          {seeAll === 3 ? "See More" : "See Less"}
        </button>
      ) : null}
    </article>
  );
};
