import { NavLink } from "react-router-dom";
import { Icon } from "@/components";
import NavItem from "./NavItem";
import "./sideBar.scss";

export default function SideBar() {
  return (
    <div className="nav-sidebar">
      <div className="logo">
        <Icon name="logo" />
      </div>

      <nav className="navs">
        <NavItem to="/home" iconName="home">
          Home
        </NavItem>

        <NavItem to="/partners" iconName="partners">
          Partners
        </NavItem>

        <NavItem to="/activity" iconName="activity">
          Activity
        </NavItem>

        <NavItem to="/teachers" iconName="teacher">
          Teacher
        </NavItem>

        <NavItem to="/profile" iconName="profile">
          Profile
        </NavItem>
      </nav>
    </div>
  );
}
