import { TeacherData } from "@/types/teacher";
import LessonCard from "./lesson-card";
import "./styles.scss";
import { useGetTeacherReview } from "@/services/teachers/get";

const Reviews = ({ teacher }: { teacher: TeacherData }) => {
  const { reviews, isLoading } = useGetTeacherReview({ id: teacher.id });

  return (
    <div className="reviews--container">
      {reviews?.reviews && reviews?.reviews.length === 0 ? (
        <p>Teacher has not received any reviews yet.</p>
      ) : isLoading ? (
        <p>Loading</p>
      ) : (
        ""
      )}
      {reviews?.reviews?.map((review) => {
        return (
          <LessonCard
            avatarUrl={review.user.profilePictureUrl}
            rating={review.rating}
            date={review.createdAt.split("T")[0]}
            firstName={review.user.firstName}
            lastName={review.user.lastName}
            remark={review.review}
          />
        );
      })}
    </div>
  );
};

export default Reviews;
