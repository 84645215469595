import { ReactNode, useState } from "react";
import "./styles.scss";

export interface Item {
  label: string | ReactNode;
  name: string;
}

type NavProps = Omit<React.HTMLAttributes<HTMLElement>, "onChange"> & {
  nav_id: string;
  items: Item[];
  selectedItem: Item;
  onChange: (selectedNav: Item) => void;
};

export const ScheduleOptions = ({
  items,
  selectedItem,
  onChange,
  nav_id,
  ...props
}: NavProps) => {
  const handleClick = (item: Item) => {
    onChange(item);
  };
  return (
    <nav className="nav-container" id={nav_id} {...props}>
      {items.map((item, key) => (
        <div
          key={key}
          onClick={() => handleClick(item)}
          className="nav-item"
          data-active={selectedItem.name === item.name ? "active" : "in-active"}
          id={nav_id + item.name}
        >
          {item.label}
        </div>
      ))}
    </nav>
  );
};
