import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ICountry } from "@/types/countryAndState";
import axios from "axios";

interface CountryState {
  countries: ICountry[];
  loading: boolean;
  error: string | null;
}

const initialState: CountryState = {
  countries: [],
  loading: false,
  error: null,
};

export const fetchCountryAndStates = createAsyncThunk<
  { countries: ICountry[] },
  void,
  { rejectValue: string }
>("country/fetch", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      "https://countriesnow.space/api/v0.1/countries/states"
    );

    return { countries: response?.data?.data }; // Adjusted path for the correct response structure
  } catch (err: any) {
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});


const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountryAndStates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchCountryAndStates.fulfilled,
        (state, action: PayloadAction<{ countries: ICountry[] }>) => {
            state.countries = action.payload.countries;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        fetchCountryAndStates.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred.";
        }
      )
      
  },
});

export default countrySlice.reducer;
