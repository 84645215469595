import { TeacherData } from "@/types/teacher";
import "./styles.scss";
import { Button, Icon } from "@/components";
import truncateString from "@/utils/truncateString";
import { useNavigate } from "react-router-dom";
import { useLessonContext } from "@/contexts/lesson-context";

export default function TeacherCard({ teacher }: { teacher: TeacherData }) {
  const navigate = useNavigate();

  const { updateValues, values } = useLessonContext();

  const handleCardClick = () => navigate(`/teachers/${teacher.id}`);
  const handleBookingClick = () => {
    updateValues({ ...values, teacher: teacher });
    navigate(`/teachers/${teacher.id}?booking=true`);
  };

  return (
    <div className="teacher_card--container">
      <div className="teacher-main">
        <div className="profile">
          <div className="avatar-container">
            <img src={teacher?.dpUrl} alt="avatar" />
          </div>
          <div className="profile-right">
            <h1 onClick={handleCardClick}>
              {teacher?.firstName} {teacher?.lastName}
            </h1>
            <h3>{teacher?.teachingType}</h3>
            <div className="interests">
              {teacher?.interests?.map((interest, key) => (
                <div className="interest" key={key}>
                  {interest}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="languages-container">
          <div className="icon-container">
            <Icon name="school" />
          </div>

          <div className="languages">
            {teacher?.languagesTaught?.map((language, key) => (
              <div key={key} className="language">
                <span className="language-name">{language?.language}</span>
                <span className="language-proficiency">
                  {language?.proficiency}
                </span>
              </div>
            ))}
          </div>
        </div>
        <p className="bio">{truncateString(teacher?.aboutMe, 150)}</p>
      </div>
      <div className="teacher-bottom">
        <div className="price-container">
          <span className="title">Lessons from</span>
          <span className="price">{teacher?.minLesson}</span>
        </div>
        <div className="btn-containers">
          <Button
            variant="neutral"
            className="send-btn"
            onClick={handleBookingClick}
          >
            Book
          </Button>
          <Button
            onClick={() => navigate("../notification")}
            variant="neutral"
            className="msg-btn"
          >
            Message
          </Button>
        </div>
      </div>
    </div>
  );
}
