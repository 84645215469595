import { Icon, Button } from "@/components";
import "./styles.scss";
import {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

type ProfileCardProps<T extends { value: string }> =
  | {
      title: string | ReactNode;
      value: string;
      type: "text";
      onRemoveItem?: () => void;
      onClick?: () => void;
      isEditable: boolean;
      onChange: (value: string) => void;
      onBlur?: (value: string) => void;
    }
  | {
      title: string | ReactNode;
      value: T[];
      type: "list";
      onRemoveItem?: (item: T) => void;
      onClick?: () => void;
      isEditable: boolean;
      onChange?: (value: string) => void;
      onBlur?: (value: string) => void;
    }
  | {
      title: string | ReactNode;
      value: string;
      type: "selectOne";
      onClick?: () => void;
      onRemoveItem?: () => void;
      isEditable: boolean;
      onChange?: (value: string) => void;
      onBlur?: (value: string) => void;
    };

export function ProfileCard<T extends { value: string }>({
  type,
  title,
  value,
  onClick,
  isEditable,
  onRemoveItem,
  onChange,
  onBlur,
}: ProfileCardProps<T>) {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTopClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (isEditable) setIsEditing(!isEditing);
    onClick?.();
  };

  useEffect(() => {
    if (isEditing) inputRef.current?.focus();
  }, [isEditing]);

  useEffect(() => {
    const listener = () => setIsEditing(false);
    window.addEventListener("click", listener);

    return () => window.removeEventListener("click", listener);
  }, []);

  return (
    <div className="profile-card">
      <Button variant="neutral" className="top" onClick={handleTopClick}>
        {typeof title === "string" ? <span>{title}</span> : title}
        <Icon name="caret_right" />
      </Button>
      <div className="main" onClick={(e) => e.stopPropagation()}>
        {type === "text" ? (
          typeof value === "string" ? (
            isEditing ? (
              <input
                ref={inputRef}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={(e) => {
                  onBlur?.(e.target.value);
                  setIsEditing(false);
                }}
              />
            ) : (
              <p>{value}</p>
            )
          ) : isEditing ? (
            <input
              ref={inputRef}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onBlur={(e) => {
                onBlur?.(e.target.value);
                setIsEditing(false);
              }}
            />
          ) : (
            value
          )
        ) : type === "list" ? (
          type === "list" && (
            <div className="row">
              {value.map((item, index) => (
                <div
                  className="profile-card--item"
                  onClick={(e) => e.stopPropagation()}
                >
                  {item.value}
                  <Button
                    key={index}
                    variant="neutral"
                    onClick={() => onRemoveItem?.(item)}
                  >
                    <Icon name="close" width={18} height={18} />
                  </Button>
                </div>
              ))}
            </div>
          )
        ) : (
          <p>{value}</p>
        )}
      </div>
    </div>
  );
}
