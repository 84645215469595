import { ReactNode } from "react";
import { AuthContextProvider } from "./auth-context";
import { LessonProvider } from "./lesson-context";
import { Toaster } from "sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LanguageProvider } from "./language-context";

export const Providers = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient();
  return (
    <>
      <Toaster position="top-right" richColors />
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <LanguageProvider>
            <LessonProvider>{children}</LessonProvider>
          </LanguageProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </>
  );
};
