import { Button, Icon } from "@/components";
import "./styles.scss";
import { IProfile } from "@/types/profile";
import { useState } from "react";
import GeneralSettings from "./GeneralSettings";
import PasswordSettings from "./PasswordSettings";
import { useNavigate } from "react-router-dom";
import { About } from "../About/index";

export default function Settings({
  open,
  onClose,
  profile,
}: {
  open: boolean;
  onClose: () => void;
  profile: IProfile;
}) {
  const navigate = useNavigate();
  const [activeSetting, setActiveSetting] = useState("");
  if (!open) return <></>;
  if (activeSetting === "general")
    return (
      <GeneralSettings profile={profile} onClose={() => setActiveSetting("")} />
    );
  if (activeSetting === "password")
    return <PasswordSettings onClose={() => setActiveSetting("")} />;
  if (activeSetting === "about")
    return <About onClose={() => setActiveSetting("")} />;

  return (
    <div className="settings_container">
      <Button variant="neutral" className="close-btn" onClick={onClose}>
        <Icon name="close" />
        <span>Close</span>
      </Button>

      <h1 className="heading">Settings</h1>
      <div className="btn-list">
        <Button
          variant="neutral"
          className="btn-item"
          onClick={() => setActiveSetting("general")}
        >
          <span className="title">General Settings</span>
          <Icon name="caret_right" />
        </Button>
        <Button
          variant="neutral"
          className="btn-item"
          onClick={() => setActiveSetting("password")}
        >
          <span className="title">Password Settings</span>
          <Icon name="caret_right" />
        </Button>
        <Button
          onClick={() => setActiveSetting("about")}
          variant="neutral"
          className="btn-item"
        >
          <span className="title">About</span>
          <Icon name="caret_right" />
        </Button>
      </div>
    </div>
  );
}
