import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../../services/api-request";

export interface Availability {
  available: {
    availability: { start: string; end: string }[];
  };
}

interface TeacherAvailabilityState {
  data: Availability | null;
  loading: boolean;
  error: string | null;
}

const initialState: TeacherAvailabilityState = {
  data: null,
  loading: false,
  error: null,
};

export const teacherAvailabilityDataFetch = createAsyncThunk<
  Availability,
  { token: string; id: string },
  { rejectValue: string }
>("teacherAvailability", async ({ token, id }, { rejectWithValue }) => {
  console.log(id);
  try {
    const { response } = await GET(
      `/teachers/availability?teacherId=${id}`,
      token
    );

    if (!response?.data?.result) {
      throw new Error("No availability data found.");
    }

    return response.data.result as Availability;
  } catch (err: any) {
    const errorMessage =
      err.response?.data?.message ||
      "An error occurred while fetching availability.";
    return rejectWithValue(errorMessage);
  }
});

const teacherAvailabilityDataSlice = createSlice({
  name: "teacherAvailabilities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(teacherAvailabilityDataFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        teacherAvailabilityDataFetch.fulfilled,
        (state, action: PayloadAction<Availability>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        teacherAvailabilityDataFetch.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An unknown error occurred.";
        }
      );
  },
});

export default teacherAvailabilityDataSlice.reducer;
