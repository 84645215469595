// import HomeIcon from "@mui/icons-material/Home";
import {
  MdOutlineHome,
  MdOutlinePeople,
  MdOutlineGames,
  MdOutlineSchool,
  MdOutlinePerson,
  MdOutlineRemoveRedEye,
  MdReplay,
} from "react-icons/md";

import { IconPropTypes } from "..";

const Home = ({ color, size, ...props }: IconPropTypes) => (
  <MdOutlineHome size={size} color={color} />
);
const Partner = ({ color, size, ...props }: IconPropTypes) => (
  <MdOutlinePeople size={size} color={color} />
);
const Activity = ({ color, size, ...props }: IconPropTypes) => (
  <MdOutlineGames size={size} color={color} />
);
const Teacher = ({ color, size, ...props }: IconPropTypes) => (
  <MdOutlineSchool size={size} color={color} />
);

const Profile = ({ color, size, ...props }: IconPropTypes) => (
  <MdOutlinePerson size={size} color={color} />
);

const Eye = ({ color, size, ...props }: IconPropTypes) => (
  <MdOutlineRemoveRedEye size={size} color={color} />
);

const Replay = ({ color, size, ...props }: IconPropTypes) => (
  <MdReplay size={size} color={color} />
);

export { Home, Partner, Activity, Teacher, Profile, Eye, Replay };
