import { TabSwitch } from "@/components/TabSwitch";
import { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import LessonDetails from "./lesson-details";
import { Button, Icon } from "@/components";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import DateSelect from "./date-select";
import { useGetTeacherLessons } from "@/services/teachers/get";
import { useLessonContext } from "@/contexts/lesson-context";
import { ClassType } from "./class-type/index";
import { Summary } from "./summary";

function EnrolmentTabs({ close }: { close: () => void }) {
  const [searchParams] = useSearchParams();
  const urlParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { updateValues, values } = useLessonContext();
  const { lessons, isLoading } = useGetTeacherLessons({
    id: urlParams.teacher_id ?? "",
  });

  const tabList = useMemo(() => {
    return (
      lessons?.result?.languagesTaught.map((language) => ({
        name: language.name,
        label: language.name,
      })) ?? []
    );
  }, [lessons]);
  const [activeTab, setActiveTab] = useState(tabList?.[0]?.name);
  const activeLessons = useMemo(() => {
    return lessons?.result?.languagesTaught.find(
      (lesson) => lesson.name === activeTab
    );
  }, [activeTab, lessons]);
  const activeLessonDetails = useMemo(() => {
    const lessonId = searchParams.get("lesson");
    if (lessonId)
      return activeLessons?.lessons.find(
        (lesson) => lesson.lessonId === lessonId
      );
    return null;
  }, [searchParams.get("lesson")]);

  const handleClickEnrolment = (
    lessonId: string,
    lesson: {
      name: string;
      description: string;
      teacherId: string;
    }
  ) => {
    const params = new URLSearchParams(searchParams);
    params.set("lesson", lessonId);
    updateValues({
      ...values,
      lessonId: lessonId,
      item: lesson,
      marchantId: lesson.teacherId,
    });
    navigate(`${location.pathname}?${params.toString()}`);
  };

  useEffect(() => {
    if (!!tabList) {
      setActiveTab(tabList?.[0]?.name);
    }
  }, [tabList]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (searchParams.get("summary")) {
    return (
      <div>
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => navigate(-1)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <Summary />
      </div>
    );
  }
  if (searchParams.get("class")) {
    return (
      <div>
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => navigate(-1)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <ClassType />
      </div>
    );
  }

  if (searchParams.get("sublesson")) {
    return (
      <div>
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => navigate(-1)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <DateSelect />
      </div>
    );
  }
  if (searchParams.get("lesson")) {
    return (
      <div>
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => navigate(-1)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <LessonDetails lesson={activeLessonDetails} />
      </div>
    );
  }

  return (
    <div className="enrolment--tabs">
      <Button
        variant="neutral"
        className="enrolment--tabs__close-btn"
        onClick={close}
      >
        <Icon name="close" />
        <span>Close</span>
      </Button>
      <TabSwitch
        onSelectedChange={setActiveTab}
        list={tabList}
        defaultSelected={tabList?.[0]?.name}
      />
      <div className="enrolment--container">
        {activeLessons?.lessons.map((lesson) => (
          <div key={lesson?.lessonId} className="enrolment-lesson-card">
            <p className="lesson-title">{lesson?.name}</p>
            <p className="lesson-description">{lesson?.description}</p>
            <button
              className="enrolment-btn"
              onClick={() => handleClickEnrolment(lesson?.lessonId, lesson)}
            >
              continue
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EnrolmentTabs;
