import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST, PUT } from "../../../services/api-request";
import { IProfile, UserProfile, IEVerify } from "@/types/profile";

interface ProfileState {
  data: IProfile | null;
  loading: boolean;
  updating: boolean;
  error: string | null;
  verify: IEVerify | null;
}

const initialState: ProfileState = {
  data: null,
  loading: false,
  updating: false,
  error: null,
  verify: null,
};

export const fetchProfile = createAsyncThunk<
  IProfile,
  { token: string },
  { rejectValue: string }
>("profile/fetch", async ({ token }, { rejectWithValue }) => {
  try {
    const { response } = await GET(`/users/details`, token);

    return response?.data?.result;
  } catch (err: any) {
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

export const updateUserProfile = createAsyncThunk<
  UserProfile,
  { token: string; profileData: Partial<UserProfile> },
  { rejectValue: string }
>("profile/update", async ({ token, profileData }, { rejectWithValue }) => {
  try {
    const { response } = await PUT("/users/update", profileData, token);
    console.warn(response);
    return response?.data;
  } catch (err: any) {
    console.warn(err.response?.data?.message);
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

export const fetchEmailVerify = createAsyncThunk<
  IEVerify,
  { token: string; email: string },
  { rejectValue: string }
>("emailVerify/fetch", async ({ token, email }, { rejectWithValue }) => {
  try {
    const { response } = await GET(
      `auth/check-email-confirmation?email=${email}`,
      token
    );

    return response?.data?.result;
  } catch (err: any) {
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

export const uploadProfilePicture = createAsyncThunk<
  string,
  { token: string; file: File },
  { rejectValue: string }
>(
  "profile/uploadProfilePicture",
  async ({ token, file }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const { response } = await POST(
        "/users/profile-picture",
        formData,
        token,
        "multipart/form-data" as any
      );

      return response.data.profilePictureUrl;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to upload"
      );
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchProfile.fulfilled,
        (state, action: PayloadAction<IProfile>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        fetchProfile.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred.";
        }
      )
      // Handle Update Profile

      .addCase(updateUserProfile.pending, (state) => {
        state.updating = true;
        state.error = null;
      })
      .addCase(
        updateUserProfile.fulfilled,
        (state, action: PayloadAction<UserProfile>) => {
          state.data = {
            ...state.data,
            ...action.payload,
          } as IProfile;
          state.updating = false;
          state.error = null;
        }
      )
      .addCase(
        updateUserProfile.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.updating = false;
          state.error = action.payload || "Failed to update profile.";
        }
      )

      // Handle Upload Profile Picture

      .addCase(uploadProfilePicture.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        uploadProfilePicture.fulfilled,
        (state, action: PayloadAction<string>) => {
          if (state.data) {
            state.data.profilePictureUrl = action.payload;
          }
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        uploadProfilePicture.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Failed to upload profile picture.";
        }
      )

      // Handle email verification

      .addCase(fetchEmailVerify.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchEmailVerify.fulfilled,
        (state, action: PayloadAction<IEVerify>) => {
          state.verify = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchEmailVerify.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error =
            action.payload || "Failed while verifying email status.";
        }
      );
  },
});

export default profileSlice.reducer;
