import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import "./styles.scss";
import { toast } from "sonner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "@/utils/function";
import {
  API_URL,
  APPLICATION_ID,
  SQUARE_LOCATION_ID,
} from "@/services/constants";
import { useLessonContext } from "@/contexts/lesson-context";

export const PayWithCard = () => {
  const [value, updateValue] = useState({
    data: "",
    loading: false,
    error: "",
  });

  const navigate = useNavigate();
  const { values } = useLessonContext();

  const handlePayment = async (nonce: any) => {
    updateValue({ ...value, loading: true });

    const token = localStorage.getItem("pluri_token");

    const raw = JSON.stringify({
      lessonId: values.lessonId,
      merchantId: values.marchantId,
      timeSlots: values.timeSlots,
      packageId: values.packageId,
      payment: {
        amount: values.totalPrice,
        paymentType: "card",
        nonce: nonce,
        currency: "USD",
      },
    });

    try {
      const response = await fetch(`${API_URL}/bookings/lesson`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: raw,
      });

      if (!response.ok) {
        updateValue({ ...value, loading: false });
        toast.error(`Payment failed with status ${response.status}`, {
          richColors: true,
        });
      } else {
        updateValue({ ...value, loading: false });
        const data = await response.json();
        console.log("Payment successful:", data);
        toast.success("Payment successful!", { richColors: true });
        navigate(-1);
      }
    } catch (error: any) {
      updateValue({ ...value, loading: false });
      console.error("Payment failed:", error.message);
      toast.error("Payment failed. Please try again.", { richColors: true });
    }
  };

  return (
    <div className="container">
      <PaymentForm
        applicationId={APPLICATION_ID || ""}
        locationId={SQUARE_LOCATION_ID || ""}
        cardTokenizeResponseReceived={(token) => {
          if (token.status === "OK") {
            handlePayment(token.token);
          } else {
            toast.error("Something went wrong...", { richColors: true });
          }
        }}
      >
        <CreditCard
          buttonProps={{
            isLoading: value.loading,
            css: {
              "[data-theme='dark'] &": {
                backgroundColor: "#61dafb",
                color: "var(--ifm-color-emphasis-100)",
                "&:hover": {
                  backgroundColor: "#0091ea",
                },
              },
              backgroundColor: "#771520",
              fontSize: "14px",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#530f16",
                color: "#fff",
              },
            },
          }}
          style={{
            input: {
              fontSize: "14px",
            },
            "input::placeholder": {
              color: "#771520",
            },
          }}
        >
          {value.loading
            ? `Processing ${currencyFormat(values.totalPrice)}`
            : `Pay ${currencyFormat(values.totalPrice)}`}
        </CreditCard>
      </PaymentForm>
    </div>
  );
};
