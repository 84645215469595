import { useState } from "react";
import "./styles.scss";
import { Button, Icon } from "@/components";
import Chat from "./messages";
import Notifications from "./notifications";
import AvatarImg from "./Frame 194.png";
import Header from "@/layouts/dashboard-layout/header/Header";
import { useNavigate } from "react-router-dom";

const notifications = [
  {
    id: 1,
    title: "Welcome To Pluritongues",
    image: "https://via.placeholder.com/600x200",
    content:
      "We here at pluritongues pluritongues pluritongues pluritongues pluritongues...",
    date: "January 15, 2024",
    details: {
      title: "Pluritongues Support",
      image: "https://via.placeholder.com/600x200", // Replace with your image URL
      content: `
        The best way for management, teachers, professors, lecturers, and principals to welcome students is with endearing words that cultivate a sense of trust...
      `,
      date: "Friday 27, October 2024",
    },
  },
];

const messages = [
  {
    id: 1,
    receiver: "Che Che Sober",
    image: "https://via.placeholder.com/600x200",
    chats: [
      {
        sender: "Che Che Sober",
        avatar: "/path-to-avatar.jpg",
        message:
          "Hi, I would like to make an enquiry about your Igbo lesson packages.",
        date: "Friday 27, October 2024",
      },
      {
        sender: "me",
        avatar: "/path-to-user-avatar.jpg",
        message: "What would you like to know?",
        date: "Friday 27, October 2024",
      },
      {
        sender: "Che Che Sober",
        avatar: "/path-to-avatar.jpg",
        message: "Can I schedule lessons for the weekend?",
        date: "Saturday 28, October 2024",
      },
      {
        sender: "Che Che Sober",
        avatar: "/path-to-avatar.jpg",
        message: "Can I schedule lessons for the weekend?",
        date: "Saturday 28, October 2024",
      },
      {
        sender: "Che Che Sober",
        avatar: "/path-to-avatar.jpg",
        message: "Can I schedule lessons for the weekend?",
        date: "Saturday 28, October 2024",
      },
      {
        sender: "Che Che Sober",
        avatar: "/path-to-avatar.jpg",
        message: "Can I schedule lessons for the weekend?",
        date: "Saturday 28, October 2024",
      },
      {
        sender: "me",
        avatar: "/path-to-user-avatar.jpg",
        message: "Yes, weekend lessons are available.",
        date: "Saturday 28, October 2024",
      },
      {
        sender: "me",
        avatar: "/path-to-user-avatar.jpg",
        message: "Yes, weekend lessons are available.",
        date: "Saturday 28, October 2024",
      },
      {
        sender: "me",
        avatar: "/path-to-user-avatar.jpg",
        message: "Yes, weekend lessons are available.",
        date: "Saturday 28, October 2024",
      },
      {
        sender: "me",
        avatar: "/path-to-user-avatar.jpg",
        message: "Yes, weekend lessons are available.",
        date: "Saturday 28, October 2024",
      },
    ],
  },
];

const NotificationPage = () => {
  const [selectedNotification, setSelectedNotification] = useState(
    notifications[0]
  );
  const [selectedMessage, setSelectedMessage] = useState(messages[0]);
  const [activeTab, setActiveTab] = useState<"notification" | "message">(
    "notification"
  );
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="notification-page">
        <Button
          onClick={() => navigate(-1)}
          variant="neutral"
          className="go-back"
        >
          <Icon name="left_arrow" />
          Back
        </Button>

        {/* Page Title */}
        <h1 className="page-title">Message & Notification</h1>

        <div className="notification-container">
          {/* Notifications List */}
          <div className="notification-list">
            <div className="tabs">
              <button
                className={`tab ${
                  activeTab === "notification" ? "active" : ""
                }`}
                onClick={() => setActiveTab("notification")}
              >
                Notification
              </button>
              <button
                className={`tab ${activeTab === "message" ? "active" : ""}`}
                onClick={() => setActiveTab("message")}
              >
                Message
              </button>
            </div>
            {activeTab === "notification" &&
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`notification-item ${
                    selectedNotification.id === notification.id ? "active" : ""
                  }`}
                  onClick={() => setSelectedNotification(notification)}
                >
                  <div className="image-container">
                    {/* Replace with an actual image URL if available */}
                    <img src={AvatarImg} alt="Avatar" />
                  </div>
                  <div className="text-container">
                    <h2 className="notification-title">{notification.title}</h2>
                    <p className="notification-content">
                      {notification.content}
                    </p>
                    <p className="notification-date">{notification.date}</p>
                  </div>
                </div>
              ))}
            {activeTab === "message" &&
              messages.map((message) => (
                <div
                  key={message.id}
                  className={`notification-item ${
                    selectedMessage.id === message.id ? "active" : ""
                  }`}
                  onClick={() => setSelectedMessage(message)}
                >
                  <div className="image-container">
                    {/* Replace with an actual image URL if available */}
                    <img
                      src={message.image || "default-image.png"}
                      alt="Avatar"
                    />
                  </div>
                  <div className="text-container">
                    <h2 className="notification-title">{message.receiver}</h2>
                    <p className="notification-content">
                      {message.chats[message.chats.length - 1].message}
                    </p>
                    <p className="notification-date">
                      {message.chats[message.chats.length - 1].date}
                    </p>
                  </div>
                </div>
              ))}
          </div>

          <div className="notification-details">
            {activeTab === "message" ? (
              <Chat chats={selectedMessage.chats} />
            ) : (
              <Notifications selectedNotification={selectedNotification} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPage;
