import { SearchInput } from "@/components";
import "./styles.scss";
import { useState } from "react";

interface Props<T extends { id: string; value: string }> {
  title: string;
  list: T[];
  selectedItem: T | null;
  onChange: (selectedItem: T | null) => void;
}

export default function SelectFromList<
  T extends { id: string; value: string }
    >({ title, list, selectedItem, onChange }: Props<T>) {
  const [filtered, setFiltered] = useState<T[]>(list);
  const isSelected = (item: T) => {
    return selectedItem?.id === item.id;
  };

  const toggleSelection = (item: T) => {
    onChange(item);
  };

  const handleFilter = (value: string) => {
    const filteredList = list.filter((item) =>
      item.value.toLowerCase().includes(value.toLowerCase())
    );
    setFiltered(filteredList);
  };

  return (
    <div className="country-list-container">
      <h1 className="heading">{title}</h1>
      <SearchInput onChange={(e) => handleFilter(e.target.value)} />
      <div className="list">
        {filtered.map((item, index) => (
          <div
            className={`list-item ${isSelected(item) ? "selected" : ""}`}
            key={index}
            onClick={() => toggleSelection(item)}
          >
            <span className="list-item-text">{item.value}</span>
            <input
              type="checkbox"
              checked={isSelected(item)}
              onChange={() => toggleSelection(item)}
              className="checkbox"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
