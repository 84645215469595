import { TabSwitch } from "@/components/TabSwitch";
import { TeacherData } from "@/types/teacher";
import "./styles.scss";
import { useState } from "react";
import AboutMe from "./about-me";
import MyResume from "./my-resume";
import Reviews from "./reviews";

export const MoreDetails = ({ teacher }: { teacher: TeacherData }) => {
  const [activeTab,setActiveTab] = useState("about_me")
  const tabList = [
    { name: "about_me", label: "About Me" },
    { name: "resume", label: "Resume" },
    { name: "reviews", label: "Reviews" },
  ];
  return (
    <div className="teacher_details--container">
      <TabSwitch onSelectedChange={setActiveTab} list={tabList} defaultSelected="about_me" />
      <div>
        {
          activeTab === "about_me" &&
          <AboutMe teacher={teacher}/>
        }
        {
          activeTab === "resume" &&
          <MyResume teacher={teacher}/>
        }
        {
          activeTab === "reviews" &&
          <Reviews teacher={teacher}/>
        }
      </div>
    </div>
  );
};
