import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../../services/api-request";
import { TeacherData } from "@/types/teacher";

interface TeacherListState {
  data: TeacherData | null;
  loading: boolean;
  error: string | null;
}

const initialState: TeacherListState = {
  data: null,
  loading: false,
  error: null,
};

export const teachersListFetch = createAsyncThunk<
  TeacherData,
  { token: string },
  { rejectValue: string }
>("teachers-list/fetch", async ({ token }, { rejectWithValue }) => {
  try {
    const { response } = await GET(`/teachers`, token);

    return response?.data?.result;
  } catch (err: any) {
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

const teachersListSlice = createSlice({
  name: "teachers-list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(teachersListFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        teachersListFetch.fulfilled,
        (state, action: PayloadAction<TeacherData>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        teachersListFetch.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred.";
        }
      );
  },
});

export default teachersListSlice.reducer;
