import type { Activity } from "@/types/activity";
import { SelectWhatYouHearQuestion } from "./SelectCorrectImage";
import { FillTheGapQuestion } from "./FillTheGapQuestion";
import { TapWhatYouHearQuestion } from "./TapWhatYouHear";
import { CrossWordMatchQuestion } from "./CrossWordMatch";
import { SingleWordMatchQuestion } from "./SingleWordMatch";
import { VideoContentQuestions } from "./VideoContentQuestion";

type QuestionType = Activity["activities"][number];

interface QuestionInterface {
  question: QuestionType;
  handleNextQuestion: () => void;
}

export const Question = (props: QuestionInterface): React.ReactElement => {
  const { question, handleNextQuestion } = props;
  const components: { [key: string]: React.FC<any> } = {
    SelectCorrectImageActivity: SelectWhatYouHearQuestion,
    FillTheGapActivity: FillTheGapQuestion,
    TapInWhatYouHear: TapWhatYouHearQuestion,
    CrossWordActivity: CrossWordMatchQuestion,
    MatchTheCorrectWord: SingleWordMatchQuestion,
    VideoContentActivity: VideoContentQuestions,
  };

  const Component = components[question.activityType];
  return Component ? (
    <Component question={question} handleNextQuestion={handleNextQuestion} />
  ) : (
    <DefaultComponent />
  );
};

function DefaultComponent() {
  return (
    <div>
      <p> Unknown Component</p>
    </div>
  );
}
