import Icon from "../Icon";
import "./styles.scss";
import { useRef, useState } from "react";

interface Props {
  src: string;
  onUpload: (file: File) => void;
}

export default function Avatar({ src, onUpload }: Props) {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onUpload(file);
      setFileUrl(URL.createObjectURL(file));
    }
  };

  return (
    <div
      style={{ backgroundImage: `url(${src || fileUrl})` }}
      className="avatar_container"
    >
      <div className="camera-icon" onClick={handleIconClick}>
        <Icon className="camera" name="camera" />
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
}
