import { TeacherData } from "@/types/teacher";
import "./styles.scss";
import { Icon } from "@/components";

const AboutMe = ({ teacher }: { teacher: TeacherData }) => {
  return (
    <div className="about_me--container">
      <div className="field-container">
        <span className="label">From:</span>
        <span className="value">Nigeria</span>
      </div>
      <div className="field-container">
        <span className="label">Currently Living in:</span>
        <span className="value">Lagos,Nigeria</span>
      </div>
      <div className="field-container">
        <div className="label">speaks:</div>
        <div className="languages">
          {teacher?.languagesSpoken?.map((language) => (
            <div key={language?.language} className="language">
              <span className="language-name">{language?.language}</span>
              <span className="language-proficiency">
                {language?.proficiency}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="field-container">
        <span className="label">Interests:</span>
        <div className="languages">
          {teacher?.interests?.map((interest) => (
            <div key={interest} className="language">
              <span className="language-name">{interest}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="field-container">
        <div className="languages-container">
          <div className="icon-container">
            <Icon name="school" />
          </div>
          <div className="languages">
            {teacher?.languagesTaught?.map((language) => (
              <div key={language.language} className="language">
                <span className="language-name">{language.language}</span>
                <span className="language-proficiency">
                  {language.proficiency}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="field-container">
        <span className="label">About Me:</span>
        <span className="value">
          {teacher?.aboutMe}
        </span>
      </div> */}
      <div className="field-container">
        <span className="label">About Me:</span>
        <span className="value">{teacher?.aboutYouAsTeacher}</span>
      </div>
    </div>
  );
};

export default AboutMe;
