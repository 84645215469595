// import React, { useRef, useEffect } from "react";

// interface VideoPlayerProps {
//   videoUrl: string;
//   startVideo: boolean; // Prop to trigger playback
// }

// const VideoPlayerX: React.FC<VideoPlayerProps> = ({ videoUrl, startVideo }) => {
//   const videoRef = useRef<HTMLVideoElement>(null);
//   console.log("console it:", videoUrl);

//   useEffect(() => {
//     if (startVideo && videoRef.current) {
//       videoRef.current.play(); // Explicitly play the video
//     }
//   }, [startVideo]);

//   return (
//     <video ref={videoRef} src={videoUrl} width="100%" height="auto" controls>
//       Your browser does not support the video tag.
//     </video>
//   );
// };

import { Icon } from "@/components";
import React from "react";

interface YouTubePlayerProps {
  videoUrl: string;
  width?: string;
  height?: string;
  start?: number;
}

const VideoPlayer: React.FC<YouTubePlayerProps> = ({ videoUrl }) => {
  const height = "238";
  const start = 0;
  const autoplay = false;

  const videoId = extractYouTubeVideoId(videoUrl);

  if (!videoId) {
    return (
      <div className="youtube-pluri-logo">
        <Icon name="logo" />
      </div>
    );
  }

  return (
    <div>
      <iframe
        height={height}
        src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplay}&start=${start}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="video-player"
        style={{
          width: `calc(100% + 8px)`,
          position: "absolute",
        }}
      ></iframe>
    </div>
  );
};

const extractYouTubeVideoId = (url: string): string | null => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|shorts\/|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

// export default YouTubePlayer;

export default VideoPlayer;
