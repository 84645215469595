import NavItem from "./NavItem";
import "./bottom-nav.scss";

export default function BottomNav() {
  return (
    <div className="bottom-nav">
      <nav className="navs">
        <NavItem to="/home" iconName="home">
          Home
        </NavItem>

        <NavItem to="/partners" iconName="partners">
          Partners
        </NavItem>

        <NavItem to="/activity" iconName="activity">
          Activity
        </NavItem>

        <NavItem to="/teachers" iconName="teacher">
          Teacher
        </NavItem>

        <NavItem to="/profile" iconName="profile">
          Profile
        </NavItem>
      </nav>
    </div>
  );
}
