import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../../services/api-request";

interface Language {
  id: number;
  name: string;
}

interface LanguagesState {
  data: Language[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: LanguagesState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchLanguages = createAsyncThunk(
  "languages/fetchLanguages",
  async (
    {
      token,
      updateLanguages,
    }: { token: string; updateLanguages: (languages: Language[]) => void },
    { rejectWithValue }
  ) => {
    try {
      const { response } = await GET(`/languages/languages-taught`, token);
      const languages = response?.data?.result?.languages;

      if (languages) {
        updateLanguages(languages);
      }

      return languages;
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.message ||
        "An error occurred while fetching languages.";
      return rejectWithValue(errorMessage);
    }
  }
);

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchLanguages.fulfilled,
        (state, action: PayloadAction<Language[]>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(fetchLanguages.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default languagesSlice.reducer;
