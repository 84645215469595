import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";

interface IProp {
  onSelect: (value: string) => void;
  options: { label: string; value: string }[];
  placeholder?: string;
  value?: string;
}

const DropdownMenu: React.FC<IProp> = ({
  onSelect,
  options,
  placeholder,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      {/* Dropdown Header */}
      <div className="dropdown__header" onClick={toggleDropdown}>
        <span className="dropdown__title">
          {value ? value : placeholder ?? "Select an Option"}
        </span>
        {isOpen ? <PiCaretUpBold /> : <PiCaretDownBold />}
      </div>

      {/* Dropdown Items */}
      {isOpen && (
        <ul className="dropdown__list">
          {(options ?? []).map((item, index) => (
            <li
              onClick={() => {
                onSelect(item.value);
                setIsOpen(false);
              }}
              key={index}
              className="dropdown__item"
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownMenu;
