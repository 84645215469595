import { Icon, Button } from "@/components";
import { TeacherData } from "@/types/teacher";
import "./styles.scss";
import VideoPlayer from "./video-player";
import { useNavigate } from "react-router-dom";

export const TeacherCard = ({
  teacher,
  openBooking,
}: {
  teacher: TeacherData;
  openBooking: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <div className="teacher_card--container">
      {/* {!startVideo && (
        <div className="top" onClick={handlePlay}>
          <Icon name="play" />
        </div>
      )}
      {startVideo && ( */}
      <div className="top">
        <VideoPlayer videoUrl={teacher?.youtubeVideoUrl} />
      </div>
      {/* )} */}
      <div className="main">
        <div className="profile">
          <div className="avatar-container">
            <img src={teacher?.dpUrl} alt="avatar" />
          </div>
          <div className="profile-right">
            <h1>
              {teacher?.firstName} {teacher?.lastName}
            </h1>
            <h3>{teacher?.teachingType}</h3>
            <div className="interests">
              {teacher?.interests?.map((interest) => (
                <div className="interest" key={interest}>
                  {interest}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="languages-container">
          <div className="icon-container">
            <Icon name="chalkboard" />
          </div>
          <div className="languages">
            {teacher?.languagesTaught?.map((language) => (
              <div className="language" key={language.language}>
                <span className="language-name">{language?.language}</span>
                <span className="language-proficiency">
                  {language.proficiency}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bottom">
        <Button
          onClick={() => navigate("../../notification")}
          variant="neutral"
          className="msg-btn"
        >
          Message
        </Button>
        <Button variant="neutral" className="send-btn" onClick={openBooking}>
          Book
        </Button>
      </div>
    </div>
  );
};
