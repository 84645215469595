import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Lesson, Package } from "@/types/teacher";
import { currencyFormat } from "@/utils/function";
import { useLessonContext } from "@/contexts/lesson-context";

const LessonDetails: React.FC<{ lesson: Lesson | null | undefined }> = ({
  lesson,
}) => {
  const [searchParams] = useSearchParams();
  const [filteredPackages, setFilteredPackages] = useState<Package[] | null>(
    null
  );
  const { updateValues, values } = useLessonContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const [selectedInput, setSelectedInput] = useState<number | null>(0);

  const handleContinue = () => {
    if (!selectedPackage) return;
    const params = new URLSearchParams(searchParams);
    params.set("sublesson", selectedPackage?.packageId);
    updateValues({
      ...values,
      packageId: selectedPackage.packageId,
      amount: selectedPackage.price,
      quantity: selectedPackage.quantity,
      duration: selectedPackage.duration,
      tax: selectedPackage.price * 0.06,
      totalPrice: selectedPackage.price + selectedPackage.price * 0.06,
    });
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const filterPackages = (duration: number) => {
    const filtered =
      lesson?.packages?.filter((pkg) => pkg.duration === duration) ?? [];
    setFilteredPackages(filtered);
    setSelectedInput(duration);
  };

  useEffect(() => {
    filterPackages(30);
  }, [lesson?.packages]);

  return (
    <div className="intro-lessons">
      <h2 className="intro-lessons__title">{lesson?.name}</h2>
      <p className="introduction">{lesson?.description}</p>
      <div className="intro-lessons__inputs">
        <div
          className={`input-box ${
            selectedInput === 30 ? "input-box--selected" : ""
          }`}
          onClick={() => filterPackages(30)}
        >
          <p className="time">30</p>
          <p className="time">mins</p>
        </div>

        <div
          className={`input-box ${
            selectedInput === 60 ? "input-box--selected" : ""
          }`}
          onClick={() => filterPackages(60)}
        >
          <p className="time">60</p>
          <p className="time">mins</p>
        </div>
      </div>

      <div className="intro-lessons__cards">
        {filteredPackages?.map((pkg, index) => (
          <LessonPackageCard
            key={index}
            pkg={pkg}
            selectedPackage={selectedPackage as Package}
            setSelectedPackage={
              setSelectedPackage as React.Dispatch<
                React.SetStateAction<Package>
              >
            }
          />
        ))}
      </div>
      <div className="btn">
        <button
          disabled={!selectedPackage}
          className="enrolment-btn"
          onClick={handleContinue}
        >
          continue
        </button>
      </div>
    </div>
  );
};

const LessonPackageCard = ({
  pkg,
  selectedPackage,
  setSelectedPackage,
}: {
  pkg: Package;
  selectedPackage: Package;
  setSelectedPackage: React.Dispatch<React.SetStateAction<Package>>;
}) => {
  return (
    <div
      className={` ${
        selectedPackage?.packageId === pkg?.packageId
          ? "lesson-cards"
          : "lesson-cards--inactive"
      }`}
      onClick={() => setSelectedPackage(pkg)}
    >
      <div>
        <p className="times">{pkg.duration} mins</p>
      </div>
      <div>
        {/* {pkg.discount && (
          <div className="lesson-cards__discount">{pkg.discount}</div>
        )} */}
        <p className="lesson-cards__price">{currencyFormat(pkg.price)}</p>
      </div>
    </div>
  );
};

export default LessonDetails;
