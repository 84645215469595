import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  ReactNode,
  useEffect,
} from "react";

interface LanguageState {
  selectedLanguage: string;
  availableLanguages: string[];
}

interface LanguageContextType {
  values: LanguageState;
  show: boolean;
  setShow: (as: boolean) => void;
  updateLanguage: (newLanguage: string) => void;
  setAvailableLanguages: (languages: string[]) => void;
  resetLanguage: () => void;
}

const LanguageContext = createContext<LanguageContextType | null>(null);

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const [values, setValues] = useState<LanguageState>({
    selectedLanguage: "",
    availableLanguages: [],
  });
  const [show, setShow] = useState<boolean>(false);

  const updateLanguage = useCallback((newLanguage: string) => {
    setValues((prev) => ({ ...prev, selectedLanguage: newLanguage }));
  }, []);

  const setAvailableLanguages = useCallback((languages: string[]) => {
    setValues((prev) => ({ ...prev, availableLanguages: languages }));
  }, []);

  const resetLanguage = useCallback(() => {
    setValues({ selectedLanguage: "", availableLanguages: [] });
  }, []);

  const loadToggleValue = async () => {
    try {
      const toggleValue = await localStorage.getItem("toggle");
      if (toggleValue !== null) {
        setShow(JSON.parse(toggleValue));
      }
    } catch (error) {
      // console.error("Error loading toggle value:", error);
    }
  };

  useEffect(() => {
    loadToggleValue();
  }, []);

  const contextValue = useMemo(
    () => ({
      values,
      updateLanguage,
      setAvailableLanguages,
      resetLanguage,
      show,
      setShow,
    }),
    [
      values,
      updateLanguage,
      setAvailableLanguages,
      resetLanguage,
      setShow,
      show,
    ]
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error(
      "useLanguageContext must be used within a LanguageProvider"
    );
  }
  return context;
};
