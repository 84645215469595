import React from "react";
import "./styles.scss";
import { TbBrandZoom } from "react-icons/tb";
import { SiGooglemeet } from "react-icons/si";
import { useLessonContext } from "@/contexts/lesson-context";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

export const ClassType = () => {
  const { updateValues, values } = useLessonContext();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const _classHandler = (type: string) => {
    if (!type) return;
    updateValues({ ...values, classType: type });
    const params = new URLSearchParams(searchParams);
    params.set("summary", type);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <div className="class-type-container">
      <div onClick={() => _classHandler("zoom")} className="icon-text">
        <TbBrandZoom color="#169fed" size={24} />
        <p className="class-text">Zoom</p>
      </div>
      <div onClick={() => _classHandler("google")} className="icon-text">
        <SiGooglemeet color="#169fed" size={24} />
        <p className="class-text">Google meet </p>
      </div>
    </div>
  );
};
