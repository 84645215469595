import React, { useState } from "react";
import DashboardLayout from "@/layouts/dashboard-layout";
import { ScheduleOptions, List } from "@/components";
import "./schedule-styles.scss";
import { nav_items } from "@/mock/shedule";
import { useNavigate } from "react-router-dom";
import { Item as NavItem } from "@/components/scheduleOptions";
import { ScheduleCard } from "./scheduleCard";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  upcomingScheduleDataFetch,
  completedScheduleDataFetch,
} from "@/redux/slice/schedule";
import { useAuth } from "@/contexts/auth-context";
import { ScheduleHeader } from "./schedule-header";

export const Schedule = () => {
  const navigate = useNavigate();
  const [selectedNavItem, setSelectedNav] = useState<NavItem>(nav_items[0]);
  const {
    data: upcomingData,
    loading: upcomingLoading,
    error: upcomingError,
  } = useAppSelector((state) => state.upcomingSchedule);

  const { data, error, loading } = useAppSelector(
    (state) => state.completedSchedule
  );

  const dispatch = useAppDispatch();

  const { token } = useAuth();

  const schedules = async () => {
    if (selectedNavItem.name === "completed") {
      dispatch(completedScheduleDataFetch(token ?? ""));
    } else {
      dispatch(upcomingScheduleDataFetch(token ?? ""));
    }
  };

  useEffect(() => {
    schedules();
  }, [selectedNavItem]);

  return (
    <DashboardLayout>
      <ScheduleHeader title={"Schedule"} />

      <ScheduleOptions
        nav_id="schedule-nav"
        items={nav_items}
        onChange={(value) => setSelectedNav(value)}
        selectedItem={selectedNavItem}
        style={{}}
      />
      {loading || upcomingLoading ? (
        <p>Loading</p>
      ) : (
        <List
          list_key={`schedule ${selectedNavItem.name}`}
          items={
            selectedNavItem.name === "completed"
              ? data || []
              : upcomingData || []
          }
          ItemRender={ScheduleCard}
          emptyMessage={
            selectedNavItem.name === "completed"
              ? "You have no completed lessons yet."
              : "You do not have any upcoming lessons."
          }
        />
      )}
    </DashboardLayout>
  );
};
