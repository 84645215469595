import Button from "@/components/Button";
import { VideoContentQuestion } from "@/types/activity";
import { useEffect, useMemo, useRef, useState } from "react";
import { Verdict } from "../../Verdict";
import Icon from "@/components/Icon";
import "./styles.scss";

export const VideoContentQuestions = ({
  question,
  handleNextQuestion,
}: {
  question: VideoContentQuestion;
  handleNextQuestion: () => void;
}) => {
  const [endOfPage, setEndOfPage] = useState<HTMLElement | null>(null);
  const answer = question.answer;
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const [canCheck, setCanCheck] = useState(false);
  const [showReplay, setShowReplay] = useState(false); // State to control replay button visibility
  const [showOverlay, setShowOverlay] = useState(false); // State for showing overlay on video
  const videoRef = useRef<HTMLVideoElement>(null); // Reference to the video element

  const answerOption = useMemo(
    () => question.options.find((o) => question.answer.includes(o.id)),
    [question]
  );

  const handleClickOption = (optionId: string) => {
    setCanCheck(false);
    setSelectedOptionId((prev) => (prev === optionId ? null : optionId));
  };

  const handleCheck = () => setCanCheck(true);

  const handleVideoEnd = () => {
    setShowReplay(true); // Show the replay button when the video ends
    setShowOverlay(true); // Show the overlay with options
  };

  const handleReplay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset video to the beginning
      videoRef.current.play(); // Start playing the video
      setShowReplay(false); // Hide the replay button
      setShowOverlay(false); // Hide the overlay when the video starts again
    }
  };

  // Play video automatically on component mount
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.warn("Video playback failed:", error);
      });
    }
  }, []);

  return (
    <div className="question_container" data-type={question.activityType}>
      <div className="top">
        <h1>{question.instruction}</h1>
        <div className="language_container">
          <Icon name="language" />
        </div>
      </div>
      <div className="middle">
        <div className="video-player" style={{ position: "relative" }}>
          <video
            ref={videoRef}
            onEnded={handleVideoEnd}
            controlsList="nodownload"
            playsInline
            className="video-frame"
            // style={{ maxWidth: "100%", height: "auto" }}
          >
            <source src={question.question.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {showReplay && (
            <button className="replay-button" onClick={handleReplay}>
              <Icon name="replay" size={20} /> <span>Replay</span>
            </button>
          )}
        </div>
        {/* Overlay with options */}
        {showOverlay && (
          <div className="overlay-options">
            <div className="options">
              {question.options.map((option) => (
                <VideoContentOption
                  onClick={handleClickOption}
                  canCheck={canCheck}
                  option={option}
                  selectedOptionId={selectedOptionId}
                  key={option.id}
                  is_answer={option.id === answer}
                />
              ))}
            </div>
            <Button
              disabled={selectedOptionId === null}
              variant="primary"
              className="check-btn"
              onClick={handleCheck}
            >
              Check
            </Button>
          </div>
        )}
      </div>
      <Verdict
        is_answer={answerOption?.id === selectedOptionId}
        answer={answerOption?.text ?? "No answer"}
        open={canCheck}
        onClose={() => setCanCheck(false)}
        handleNext={handleNextQuestion}
      />
    </div>
  );
};

const VideoContentOption = ({
  option,
  selectedOptionId,
  canCheck,
  is_answer,
  onClick,
}: {
  option: VideoContentQuestion["options"][number];
  canCheck: boolean;
  is_answer: boolean;
  selectedOptionId: string | null;
  onClick: (option_id: string) => void;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const playAudio = () => {
    audioRef.current?.play();
  };

  const pauseAudio = () => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    }
  };

  useEffect(() => {
    if (selectedOptionId === option.id) playAudio();
    else pauseAudio();
  }, [selectedOptionId, option]);

  return (
    <Button
      variant="neutral"
      key={option.id}
      className="option"
      data-selected={String(option.id === selectedOptionId)}
      data-correct={String(
        canCheck && option.id === selectedOptionId && is_answer
      )}
      data-wrong={String(
        canCheck && option.id === selectedOptionId && !is_answer
      )}
      onClick={() => {
        onClick(option.id);
      }}
    >
      <p>{option.text}</p>

      {option.audioUrl && (
        <audio
          src={option.audioUrl}
          ref={audioRef}
          onPlay={() => setIsAudioPlaying(true)}
          onPause={() => setIsAudioPlaying(false)}
        ></audio>
      )}
    </Button>
  );
};
