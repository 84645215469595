import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import DashboardLayout from "@/layouts/dashboard-layout";
import TeacherCard from "./TeacherCard";
import { getTeachers } from "@/services/teachers/get";
import { useLanguageContext } from "@/contexts/language-context";
import "./styles.scss";
import { TeacherData } from "@/types/teacher";

export default function TeachersPage() {
  const [filteredData, setFilteredData] = useState<TeacherData[]>([]);
  const { values } = useLanguageContext();

  const { data, isLoading } = useQuery<TeacherData[]>({
    queryKey: ["teachers"],
    queryFn: getTeachers,
  });

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  useEffect(() => {
    if (values?.selectedLanguage) {
      const lowerCaseSelectedLang = values.selectedLanguage.toLowerCase();
      const filtered = data?.filter((teacher) =>
        teacher.languagesTaught.some(
          (lang) => lang.language?.toLowerCase() === lowerCaseSelectedLang
        )
      );
      setFilteredData(filtered || []);
    } else {
      setFilteredData(data || []);
    }
  }, [values.selectedLanguage, data]);

  return (
    <DashboardLayout>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="teachers-container">
          <div className="teachers-left">
            {filteredData.length > 0 ? (
              filteredData.map((teacher) => (
                <TeacherCard key={teacher.id} teacher={teacher} />
              ))
            ) : (
              <div>No teachers found for the selected language.</div>
            )}
          </div>
          <div className="teachers-right"></div>
        </div>
      )}
    </DashboardLayout>
  );
}
