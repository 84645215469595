import { Activity, InteractiveLessons } from "@/types/activity";
import "./styles.scss";
import { ProgressBar, Button } from "@/components";
import { Link } from "react-router-dom";

export default function ActivityDetailCard({
  activity,
}: {
  activity: Activity;
}) {
  return (
    <div className="activity_card">
      <div className="top">
        <img src={activity.image ?? undefined} alt="" />
      </div>
      <div className="bottom">
        <h1>{activity.title}</h1>
        <h2>
          {0}/{activity.activities.length} lessons
        </h2>
        <ProgressBar height={16} percent={0 / activity.activities.length} />
        <p>{activity.description}</p>
        <Link to={`/activity/${activity.id}`}>
          <Button variant="secondary">Start</Button>
        </Link>
      </div>
    </div>
  );
}
