import DashboardLayout from "@/layouts/dashboard-layout";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { TeacherCard } from "./teachers-card";
import { TeachersCalendar } from "./teachers-calendar";
import { MoreDetails } from "./more-details";
import EnrolmentTabs from "./enrolment-tabs";
import {
  useGetTeacher,
  useGetTeacherReview,
  useGetTeacherStats,
} from "@/services/teachers/get";
import { StatCard } from "./enrolment-tabs/stat-card/index";

export function TeacherPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [openBooking, setOpenBooking] = useState(false);
  const teacher_id = params.teacher_id;

  const { teacher, isLoading } = useGetTeacher({ id: teacher_id ?? "" });
  const { statError, statLoading, stats } = useGetTeacherStats({
    id: teacher_id ?? "",
  });
  const { reviews } = useGetTeacherReview({ id: teacher_id ?? "" });

  const handleBooking = () => {
    setOpenBooking(true);
    const params = new URLSearchParams(searchParams);
    params.set("booking", "true");
    navigate(`${location.pathname}?${params.toString()}`);
  };

  useEffect(() => {
    if (searchParams.get("booking")) {
      setOpenBooking(true);
    }
  }, [searchParams]);

  return (
    <DashboardLayout>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <div className="teachers--container">
          <div className={`left ${openBooking ? "hidden-on-mobile" : ""}`}>
            <TeacherCard teacher={teacher} openBooking={handleBooking} />
            <TeachersCalendar />
            <div className="stats-container">
              <StatCard title="⭐️" value={reviews?.averageRating || "-"} />
              <StatCard title="Students" value={stats?.totalStudents || "-"} />
              <StatCard title="Sessions" value={stats?.totalSessions || "-"} />
              <StatCard
                title="Attendance"
                value={stats?.attendanceRate || "-"}
              />
            </div>
            <MoreDetails teacher={teacher} />
          </div>
          {openBooking && (
            <div className="right">
              <EnrolmentTabs close={() => setOpenBooking(false)} />
            </div>
          )}
        </div>
      )}
    </DashboardLayout>
  );
}
