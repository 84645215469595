import { useAuth } from "@/contexts/auth-context";
import { Navigate } from "react-router-dom";
import { ReactElement, useEffect } from "react";

import { useNavigate } from "react-router-dom";

function ProtectedRoute({ children }: { children: ReactElement }) {
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;
