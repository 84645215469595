import { useMutation } from "@tanstack/react-query";
import { IResetPassword } from "@/types/profile";
import { PATCH } from "../api-request";

export const useResetPassword = (onSuccess?: () => void,onError?: () => void) => {
    const { mutate, isPending, error } = useMutation({
        mutationFn: async ({ data, token }: { data: IResetPassword; token: string }) => {
            return PATCH("/auth/change-password", data, token);
        },
        onSuccess,
        onError,
    });
    return {
        resetPassword: mutate,
        resetting: isPending,
        error,
    }
};


