import { TeacherData } from "@/types/teacher";
import "./styles.scss";

const ResumePoint = ({
  startDate,
  endDate,
  company,
  title,
}: {
  startDate: string;
  endDate?: string;
  company: string;
  title: string;
}) => {
  return (
    <div className="resume-container__point-container">
      <div className="checkbox">
        <div className="checkmark"></div>
      </div>
      <div>
        <p className="experience">
          {startDate} - {endDate ?? "till date"} - {company}
        </p>
        <p className="job-title">{title}</p>
      </div>
    </div>
  );
};

const MyResume = ({ teacher }: { teacher: TeacherData }) => {
  return (
    <div className="resume-container">
      <div>
        <div>
          <p className="label">Work Experience</p>
          {teacher?.workExperiences[0].company === "" ? (
            <p>Pending</p>
          ) : (
            teacher?.workExperiences?.map((exp, index) => (
              <ResumePoint
                key={exp.title + index}
                startDate={exp.startDate}
                endDate={exp.endDate}
                company={exp.company}
                title={exp.title}
              />
            ))
          )}
        </div>
        <div>
          <p className="label">Education</p>
          {teacher?.educationalQualifications[0].institution === "" ? (
            <p>Pending</p>
          ) : (
            teacher?.educationalQualifications?.map((edu, index) => (
              <ResumePoint
                key={edu.startDate + index}
                startDate={edu.startDate}
                endDate={edu.endDate}
                company={edu.institution}
                title={edu.fieldOfStudy}
              />
            ))
          )}
        </div>

        <div>
          <p className="label">Certification</p>
          {teacher?.otherCertifications[0].name === "" ? (
            <p>Pending</p>
          ) : (
            teacher.otherCertifications?.map((cert, index) => (
              <div key={cert.name + index} className="title">
                <p className="experience">
                  {cert.name} {cert.issueDate}
                </p>
                <p className="experience-title">{cert.issuingOrganization}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default MyResume;
