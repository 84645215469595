import { DetailCalendar } from "@/components";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useAuth } from "@/contexts/auth-context";
import { teacherAvailabilityDataFetch } from "@/redux/slice/teacher/availability";
import { toast } from "sonner";
import { useLessonContext } from "@/contexts/lesson-context";

export const TeachersCalendar = () => {
  const [dateSelected, setDateSelected] = useState<
    { date: string; time: string }[]
  >([]);
  const [picked, setPicked] = useState<Date | string>("");
  const { values } = useLessonContext();

  const { token } = useAuth();
  const dispatch = useAppDispatch();

  const fetch = () => {
    dispatch(
      teacherAvailabilityDataFetch({
        token: token!,
        id: values.marchantId,
      })
    );
  };

  useEffect(() => {
    fetch();
  }, []);

  const { data, error, loading } = useAppSelector(
    (state) => state.teacherAvailabilities
  );

  const checkdayTime = (value: Date | string): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return value < today;
  };

  const onDateClick = (type: string, e: Date | string) => {
    if (checkdayTime(e)) {
      toast.warning(
        "You cannot select a past date, Please select a later date"
      );
    } else {
      setPicked(e);
    }
  };

  return (
    <div className="teachers_calendar--container">
      <h1>Check Teacher Availability</h1>
      {data && data.available.availability.length > 0 ? (
        <DetailCalendar
          picked={picked}
          data={data.available.availability || []}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
          onDateClick={onDateClick}
        />
      ) : null}
      <div className="sessions">
        <div className="session available">
          <div className="circle"></div>
          <span>Booked Session</span>
        </div>
        <div className="session unavailable">
          <div className="circle"></div>
          <span>Teacher Unavailable</span>
        </div>
      </div>
      <div className="instruction">
        <h1>Available Time</h1>
        <h3>Click on a day to see teacher's available time</h3>
      </div>
    </div>
  );
};
