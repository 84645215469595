import DashboardLayout from "@/layouts/dashboard-layout";
import { activities_, lessons_ } from "@/mock/activties";
import ActivityCard from "./activity-card";

export function Activties() {
  const activities = activities_;
  const lessons = lessons_;
  return (
    <DashboardLayout>
      <div>
        {activities.map((activity, index) => (
          <ActivityCard key={index} activity={activity} />
        ))}
      </div>
    </DashboardLayout>
  );
}
