import { Icon, Button } from "@/components";
import "./styles.scss";

export const About = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="legal-links--container">
      <Button variant="neutral" className="close-btn" onClick={onClose}>
        <Icon name="close" />
        <span>Close</span>
      </Button>

      <div className="scroll-container">
        <div className="about-content">
          <div className="about-avatar">
            <Icon name="logo" />
          </div>
          <div className="about-text">
            <p>
              At Pluritongues, languages and traditions come to life. At our
              core, we are driven by a profound respect for the rich diversity
              found across the continent, and a passionate commitment to sharing
              these treasures with teachers and learners worldwide. Immerse
              yourself in the rhythms and melodies of African languages.
              <br />
              <br />
              As you embark on a journey of linguistic exploration. From Swahili
              to Hausa, Yoruba to Zulu, our diligent teachers and language
              partners provide a gateway to understanding the nuances of each
              language, fostering a deep appreciation for the cultural contexts
              in which they thrive. Beyond language, our platform serves as a
              gateway to the heart and soul of Africa's diverse cultures.
              <br />
              <br />
              Through engaging multimedia content and interactive learning
              experiences, we invite you to delve into the traditions, art,
              music, cuisine, and history that shape the identities of Africa's
              peoples. Join us on this transformative journey of discovery,
              where every interaction is an opportunity to celebrate the
              richness of Africa's heritage.
            </p>
          </div>
        </div>
      </div>
      <div className="links-list">
        <a
          href="https://pluritongues.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          className="link-item"
        >
          <span className="title">Terms Of Service</span>
          <Icon name="caret_right" />
        </a>
        <a
          href="https://pluritongues.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="link-item"
        >
          <span className="title">Privacy Policy</span>
          <Icon name="caret_right" />
        </a>
      </div>
    </div>
  );
};
