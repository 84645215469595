import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from "react";

interface LessonState {
  lessonId: string;
  marchantId: string;
  duration: number;
  selectedDateTime: string;
  timeSlots: Array<{ date: string; time: string }>;
  amount: number;
  lesson: string;
  classType: string;
  tax: number;
  totalPrice: number;
  discountedAmount: string;
  item: { name: string; description: string; teacherId: string }; // Updated type
  teacher: { firstName: string; lastName: string; dpUrl: string };
  packageId: string;
  quantity: number;
}

interface LessonContextType {
  values: LessonState;
  updateValues: (newValues: Partial<LessonState>) => void;
  resetValues: () => void;
}

const LessonContext = createContext<LessonContextType | null>(null);

interface LessonProviderProps {
  children: ReactNode;
}

export const LessonProvider: React.FC<LessonProviderProps> = ({ children }) => {
  const initialState: LessonState = {
    lessonId: "",
    marchantId: "",
    duration: 0,
    selectedDateTime: "",
    timeSlots: [],
    amount: 0,
    lesson: "",
    classType: "",
    tax: 0,
    totalPrice: 0,
    discountedAmount: "",
    item: { name: "", description: "", teacherId: "" },
    teacher: { firstName: "", lastName: "", dpUrl: "" },
    packageId: "",
    quantity: 0,
  };

  const [values, setValues] = useState<LessonState>(initialState);

  const updateValues = useCallback((newValues: Partial<LessonState>) => {
    setValues((prev) => ({ ...prev, ...newValues }));
  }, []);

  const resetValues = useCallback(() => {
    setValues(initialState);
  }, []);

  const contextValue = useMemo(
    () => ({
      values,
      updateValues,
      resetValues,
    }),
    [values, updateValues, resetValues]
  );

  return (
    <LessonContext.Provider value={contextValue}>
      {children}
    </LessonContext.Provider>
  );
};

export const useLessonContext = (): LessonContextType => {
  const context = useContext(LessonContext);
  if (!context) {
    throw new Error("useLessonContext must be used within a LessonProvider");
  }
  return context;
};
