import React from "react";
import { Calendar } from "@/components";
import { Availability } from "@/redux/slice/teacher/availability";

interface BookingCalendarProps {
  data: Availability;
  dateSelected: { date: string; time: string }[]; // Corrected type for dateSelected
  setDateSelected: (dates: { date: string; time: string }[]) => void; // Corrected type for setDateSelected
  onDateClick: (type: string, value: Date | string) => void;
}

export const BookingCalendar: React.FC<BookingCalendarProps> = ({
  dateSelected,
  setDateSelected,
  data,
  onDateClick,
}) => {
  return (
    <div className="teachers_calendar--container">
      <h1>Set lesson date & time</h1>
      {data.available.availability.length > 0 && (
        <Calendar
          data={data.available.availability || []}
          dateSelected={dateSelected} // Pass dateSelected as an array of objects with start and end
          setDateSelected={setDateSelected} // Pass setDateSelected to handle array of objects
          onDateClick={onDateClick}
        />
      )}
    </div>
  );
};
